import styled from "@emotion/styled";
import {
  Pane,
  BetBackground,
  OneStrikeContainer,
  OneStrikeContainerPt,
  TwoStrikeContainer,
  SmallContainer,
  TwoStrikeContainerPt,
  NoStrikeContainer,
  NoStrikeContainerPt,
  ExtraActionsContainer,
  BigContainer,
} from "assets/images";
import { sizes } from "service";
import { mixins } from "theme";

export const BetContainer = styled.div`
  position: relative;
  width: 100%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .saving-loader {
    position: absolute;
    z-index: 1;
    color: white;
    .ant-spin-dot {
      .ant-spin-dot-item {
        background-color: white;
      }
    }
  }
  .bet-more-image {
    position: absolute;
    margin: auto;
    margin-top: 20px;
    top: 0;
    left: 0;
    right: 0;
    width: 431px;
    height: 193px;
  }
  .rolling-wrapper {
    z-index: 1;
    width: ${sizes.mainWidth};
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .jackpot-wrapper {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .bet-pane-container {
      position: relative;
      width: 516px;
      height: 753px;
      background: url(${BetBackground});
      background-size: 100% 100%;
      margin: 20px 0;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: center;
      .bet-ins-mask {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: center;
        position: absolute;
        margin: auto;
        width: 100%;
        min-height: 100%;
        background: rgba(0, 0, 0, 0.8);
        z-index: 2;
        padding: 20px 40px;
        .bet-ins-mask-content {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          .fake-dice {
            position: absolute;
            left: 0;
            top: 77px;
          }
          .handler-bet {
            position: absolute;
            bottom: -41px;
          }
        }
      }
      .bet-pane-title {
        font-size: 22px;
        /* padding: 0 33px; */
        color: #ffff45;
        background: linear-gradient(
          360deg,
          #fbe741 25%,
          #dba323 53%,
          #fbe741 72%
        );
        -webkit-text-fill-color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
        font-weight: 900;
        font-family: "Roboto";
        text-align: center;
        filter: drop-shadow(0px 10px 10px rgba(0, 71, 147, 0.5));
        text-transform: uppercase;
        /* z-index: 1001; */
        /* z-index: 1; */
      }
      .bet-pane-description {
        color: white;
        font-size: 18px;
        font-family: "Roboto";
        text-align: center;
      }
      span {
        font-family: "Roboto";
        font-weight: 700;
        color: white;
        margin-bottom: 9px;
      }
      .bet-pane-card-title {
        font-size: 16px;
        color: #ffff45;
        background: linear-gradient(
          360deg,
          #fbe741 25%,
          #dba323 53%,
          #fbe741 72%
        );
        -webkit-text-fill-color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
        font-weight: 900;
        font-family: "Roboto";
        text-align: center;
        filter: drop-shadow(0px 10px 10px rgba(0, 71, 147, 0.5));
        text-transform: uppercase;
        z-index: 1001;
      }
      .one-strike {
        background: url(${OneStrikeContainer});
        background-size: 100% 100%;
      }
      .one-strike-pt {
        background: url(${OneStrikeContainerPt});
        background-size: 100% 100%;
      }
      .one-strike-default {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 20px;
        margin-top: 290px;
        margin-left: 266px;
        width: 217px;
        height: 59px;
        line-height: 0;
        padding-top: 36px;
        font-size: 21.5px;
        text-align: center;
        font-weight: bold;
        user-select: none;
      }
      .two-strike-full-card {
        position: absolute;
        top: 0;
        left: 0;
        margin-top: 265px;
        margin-left: 94px;
        width: 103px;
        height: 21px;
      }
      .one-strike-full-card {
        position: absolute;
        top: 0;
        left: 0;
        margin-top: 265px;
        margin-left: 326px;
        width: 103px;
        height: 21px;
      }
      .no-strike-full-card {
        position: absolute;
        top: 0;
        left: 0;
        margin-top: 378px;
        margin-left: 189px;
        width: 140px;
        height: 26px;
      }
      .two-outs {

      }
      .sm-strike-container {
        background: url(${SmallContainer});
        background-size: 100% 100%;
      }
      .bg-strike-container {
        background: url(${BigContainer});
        background-size: 100% 100%;
      }
      .two-strike {
        background: url(${TwoStrikeContainer});
        background-size: 100% 100%;
      }
      .two-strike-pt {
        background: url(${TwoStrikeContainerPt});
        background-size: 100% 100%;
      }
      .two-strike-default {

        position: absolute;
        top: 0;
        left: 0;
        margin-top: 290px;
        margin-left: 32px;
        width: 217px;
        height: 59px;
        line-height: 0;
        padding-top: 36px;
        font-size: 21.5px;
        text-align: center;
        font-weight: bold;
        user-select: none;
      }
      .no-strike {
        background: url(${NoStrikeContainer});
        background: url(${NoStrikeContainerPt});
        width: 375px;
      }
      .no-strike-pt {
        background: url(${NoStrikeContainerPt});
        background-size: 100% 100%;
        width: 375px;
      }
      .no-strike-default {
        background-size: 100% 100%;
        /* width: 100%; */
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        margin-top: 409px;
        width: 375px;
        height: 103px;
        line-height: 125px;
        text-align: center;
        font-size: 30px;
        user-select: none;
      }

    }
    .pane-container {
      position: relative;
      width: ${sizes.mainWidth}px;
      height: ${sizes.mainWidth}px;
      background: url(${Pane});
      background-size: 100% 100%;
      // margin-bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      .free-pane {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
      .stop-loader {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        margin: auto;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
        z-index: 1;
      }
      .ins-mask {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: absolute;
        margin: auto;
        width: 100%;
        min-height: 100%;
        background: rgba(0, 0, 0, 0.8);
        z-index: 2;
        padding: 40px;
        .ins-mask-header {
          position: relative;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 10px;
          .icon-bone {
            position: absolute;
            left: 0px;
          }
          .ins-mask-title {
            @media (max-width: ${mixins.mobileWidth}px) {
              font-size: 22px !important;
            }
          }
        }
        .btn-gotit {
          margin-top: 15px;
          cursor: pointer;
        }
      }
      .skipping-stars {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        margin: auto;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
      .pane {
        padding: 15px;
        .ant-row {
          .ant-col {
          }
        }
      }
      .pane-mask {
        position: absolute;
        bottom: -20px;
        background: rgba(0, 0, 0, 0.9);
        border-radius: 10px;
        border: solid dimgray 1px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 44px;
      }
    }
    .dice {
      display: flex;
      justify-content: center;
      .die-container {
        .die {
          transition: transform 0.9s ease;
          .face {
            box-shadow: inset -0.35rem 0.35rem 0.75rem rgba(0, 0, 0, 0.3),
              inset 0.5rem -0.25rem 0.5rem rgba(0, 0, 0, 0.15);
            border-radius: 13px;
            border: solid 1px white;
          }
        }
      }
    }
    .btn-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      width: 100%;
      min-height: 105px;
      .guide-img,
      .paytable-img {
        min-width: 50px;
        display: flex;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
  /* @media (max-width: 412px) {
    .rolling-wrapper {
      .pane-container {
        width: 300px;
        height: 300px;
      }
      .btn-container {
        min-height: 85px;
        .roll-btn {
          width: 185px !important;
          height: 80px !important;
          span {
            font-size: 24px !important;
          }
        }
      }
    }
  } */
`;

export const BetSlideContainer = styled.div`
  margin-top: -138px;
  z-index: 2;
  min-height: 62px;
  display: flex;
  flex-direction: column;
  background-size: 100% 100%;
  padding: 5px 10px;
  /* z-index: 1001; */
  /* min-height: 115px; */
  animation-timing-function: ease-in-out;
  @keyframes betanimation {
    0% {
      transform: scale(1);
    }
    40% {
      transform: scale(1.05);
    }
    80% {
      transform: scale(1);
    }
    60% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
  .slide-bar-container {
    width: 100%;
    display: flex;
    justify-content: center;
    .bet-slider {
      width: 96%;
      .slider-wrapper {
        flex: 1;
      }
    }
  }
  .bet-btn {
    width: 255px !important;
    height: 120px;
    margin-top: -20px;

    span {
      font-size: 29px !important;
      filter: none !important;
    }
  }
  @media (max-width: ${mixins.mobileWidth}px) {
    .slide-bar-container {
      .bet-slider {
        .slider-wrapper {
          .slider {
            min-width: 140px !important;
          }
        }
      }
    }
  }
`;

export const PriceLabel = styled.div`
  border-style: solid;
  border-width: 1px;
  border-image-source: linear-gradient(to top, #673608, #c6992b, #e8c263, #ffc, #e8bc5d, #a37627, #6c3808);
  border-image-slice: 1;
  background-image: linear-gradient(to top, #a36f13, #b6cfe0, #fff, #fff), linear-gradient(to bottom, #fff, #fff);
  font-family: Roboto;
  font-size: 13.5px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const ExtraButton = styled.div`
  width: 95px;
  height: 80px;
  background: url(${ExtraActionsContainer});
  background-size: 100% 100%;
  background-position: 0 10px;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 0px 2px 0 rgba(51, 0, 102, 0.5);
  font-family: Roboto;
  font-size: 26.5px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fefefe;
`;

export const BottomContents = styled.div`
  display: flex;
  align-items: center;
  margin-top: -5px;
`;

export const Overlay = styled.div`
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
`;

// cricket

export const DefaultLabel = styled.div`
  color: #fff;
  font-size: 18px;
  position: absolute;
  font-weight: bold;
  font-family: 'Barlow Semi Condensed Bold';
  z-index: 999;

`

export const TwoOutsLabel = styled(DefaultLabel)`
  top: 38%;
  left: 20%;
`

export const OneOutLabel = styled(DefaultLabel)`
  top: 38%;
  left: 67%;
`

export const NoOutsLabel = styled(DefaultLabel)`
  font-size: 32px;
  top: 25.2rem;
  left: 39%;
`
